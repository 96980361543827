<template>
  <div class="layout-default">
    <BlockHeader :links="headerLinks" />

    <slot />

    <BlockServiceMenu
      v-if="serviceMenu?.show_service_navigation"
      :data="serviceMenu"
      :form-data="newsletterForm"
    />

    <BlockFooter v-bind="footer" />
  </div>
</template>

<script setup>
const { locale } = useI18n();

const serviceMenu = getStoryblokSetting('service');
const newsletterForm = getStoryblokSetting('newsletter');
const footer = getStoryblokSetting('footer');
const links = getStoryblokSetting('navigation', 'header_navigation');

const headerLinks = computed(() => {
    if (links.value) {
        return useMapStoryblokLinks(links.value, locale);
    }

    return [];
});

/*
    Set the language of the page
*/

useHead({
    htmlAttrs: {
        lang: locale.value,
    },
});

/*
    Get ip address and check if it is the same as the admin ip
*/
const authStore = useAuthStore();
const loginData = getStoryblokSetting('login');

const { data: autoLoginAdmin, execute } = useLazyAsyncData(
    'auto-login-admin',
    () => $fetch('https://api.ipify.org?format=json'),
    {
        transform: (data) => data.ip === loginData.value?.adminIp,
        immediate: false,
    },
);

watch(() => loginData.value, (newValue) => {
    if (newValue) {
        execute();
    }
});

watch(() => autoLoginAdmin.value, (newValue) => {
    if (newValue) {
        authStore.adminLogin();
    }
}, { immediate: true });
</script>

<style lang="scss">
.layout-default {
    position: relative;
}
</style>
